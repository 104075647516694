import React, { memo } from "react";

const SettingsComponent = () => {
  return (
    <div>
      123
    </div>
  );
};

export const Settings = memo(SettingsComponent);
